<template>
  <section class="p-validate">
    <div class="main">
      <div class="main__box">
        <DecotTitle :engTitle="$t('verify.email')" />

        <div class="desc">
          <p class="desc__text">
            {{ $t('verify.emailSendDesc') }}
          </p>
          <p class="desc__text">
            {{ email }}
          </p>
        </div>

        <div class="form-wrap">
          <re-easy-form v-model="easyForm" :rules="validateRule" :labelConfig="labelConfig" ref="reEasyForm" />

          <div class="btn-wrap">
            <re-button round="round" type="border" size="large" @click="submitEasyForm">{{
              $t("validateComplete")
            }}</re-button>
          </div>
          <div class="btn-wrap">
            <re-button round="round" type="border" size="large" @click="handleVerifyEmail" :disabled="disabled">
              <Timer ref="timerRef" :api="triggerSendSms" :btnName="$t('sendValidateEeail')" timerKey="sendBtnTimer"
                @setDisabledStatus="setDisabledStatus" />
            </re-button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import '@/assets/scss/login.scss';

// import DatePick from 'vue-date-pick';
import 'vue-date-pick/dist/vueDatePick.css';
import { verifyEmailV2, verifyEmailCodeV2, getMemberProfileV2 } from '@/lib/cakeHttp';
// verifySmsCode
import { mapGetters, mapMutations, mapState } from 'vuex';
import ReButton from '@/components/form/ReButton.vue';
import ReEasyForm from '@/components/form/ReEasyForm.vue';
import { validateRule } from '@/config/easyFormRule';
import { easyFormLabelConfig } from '@/config/easyFormLabel';
import { getApiLangCode } from '@/utils/getLangCode';
import DecotTitle from '@/components/global/DecoTitle.vue';
import Timer from '@/components/Timer.vue';

export default {
  name: 'Validate',
  components: {
    ReEasyForm,
    ReButton,
    DecotTitle,
    Timer
    // DatePick,
  },
  data() {
    return {
      disabled: false,
      dateTest: null,
      attributes: [
        {
          key: 'today',
          highlight: true,
          dates: new Date()
        }
      ],
      lightboxOpen: false,
      lightboxMessage: {
        title: '',
        info: ''
      },
      formData: {
        account: '',
        birthday: ''
      },
      txt: 'center',
      rememberMe: false,
      radioOptions: [
        {
          label: this.$t('rememberMe'),
          value: true
        }
      ],
      easyForm: [
        {
          label: '',
          prop: 'code',
          value: '',
          comp: 'auto-input',
          // size: 'small',
          count: 4,
          placeholder: ''
        }
      ],
      validateRule,
      labelConfig: easyFormLabelConfig
    };
  },
  computed: {
    ...mapState(['memberInfo']),
    ...mapGetters(['getLanguage']),
    filterData() {
      return this.easyForm.reduce((obj, item) => {
        obj[item.prop] = item.value;

        return obj;
      }, {});
    },
    email() {
      const { email } = this.memberInfo;
      return email;
    }
  },
  methods: {
    ...mapMutations(['toggleShowMsg', 'setMsgInfo', 'logIn']),
    async submitEasyForm() {
      const checkForm = await this.$refs.reEasyForm.validateForm();
      if (checkForm.status) {
        this.handleSmsCode();
      }
    },

    getMemberData() {
      getMemberProfileV2(
        (data) => {
          // login 結構與直接拿結構不同，所以多套一層

          this.logIn({
            member: data
          });

          this.toggleShowMsg(true);

          this.setMsgInfo({
            succeed: true,
            desc: this.$t('validEmail.msg.success')
          });

          this.$router.push({
            name: 'MemberData'
          });
        },
        () => { }
      );
    },
    handleSmsCode() {
      verifyEmailCodeV2(
        {
          type: 'web',
          code: this.filterData.code
        },
        // 成功
        () => {
          this.getMemberData();
        },
        // 失敗
        (err) => {
          // console.log('verify sms...err', err);
          this.toggleShowMsg(true);
          this.setMsgInfo({
            succeed: false,
            desc: err
          });
        }
      );
    },
    openLightbox(t, i) {
      this.lightboxOpen = true;
      this.lightboxMessage.title = t;
      this.lightboxMessage.info = i;
    },
    clickCloseLightbox() {
      this.lightboxOpen = false;
      this.$router.push({ path: '/login' });
    },
    triggerSendSms() {
      verifyEmailV2(
        {
          lang_id: getApiLangCode(),
          type: 'web'
        },
        () => {
          this.toggleShowMsg(true);
          this.setMsgInfo({
            succeed: true,
            desc: this.$t('verify.emailSendDesc')
          });
        },
        (err) => {
          this.toggleShowMsg(true);
          this.setMsgInfo({
            succeed: false,
            desc: err
          });
        }
      );
    },
    handleVerifyEmail() {
      this.$refs.timerRef.handleClick();
    },
    setDisabledStatus(status) {
      this.disabled = status;
    },
    checkVerified() {
      const { email_verified_at } = this.memberInfo;
      if (email_verified_at) {
        this.$router.push({
          name: 'MemberData'
        });

        return;
      }

      this.handleVerifyEmail();
    }
  },
  mounted() {
    this.checkVerified();

    if (this.getLanguage === 'en') {
      this.txt = 'left';
    }
    if (process.env.NODE_ENV === 'development') {
      this.formData = {
        account: '11111',
        birthday: '1988-01-01'
      };
    }
  },

  created() {
    // this.handleVerifyEmail();
  }
  // clickCloseLightbox() {
  //   this.lightboxOpen = false;
  //   this.$router.push({ name: 'Login' });
  // },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/other/color.scss";
@import "@/assets/scss/other/mixin.scss";

.p-validate {
  width: 100%;
  min-height: 100vh;
  margin-top: -70px;
  @include flex(center, center);
  background-color: #f8f8f8;
}

.desc {
  margin-bottom: 60px;

  &__text {
    text-align: center;
  }
}

.main {
  &__box {
    width: 350px;
  }
}

.form-wrap {
  width: 100%;
  max-width: 350px;
  margin: 0 auto;
}

.btn-wrap {
  margin-top: 50px;

  &+& {
    margin-top: 20px;
  }
}

.account-exist {
  @include font-style($c-grey1, 14);
  // @include inline-flex(center);
  margin-top: 20px;
  text-align: center;

  &__text {
    display: inline-block;
    @include font-style($c-grey1, 14);
  }

  &__to-login {
    @include inline-flex(center, center);
    @include font-size(14);

    &__icon {
      width: 15px;
      color: $c-link;
    }

    &__link {
      color: $c-link;
    }
  }
}

@media screen and (max-width: 768px) {
  .form-wrap {
    width: 90%;
  }
}
</style>
